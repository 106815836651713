// Login.jsx
import React, { useState } from "react";
import "./login.scss";
import { handleApiRequest } from "../../functions/services";
import { isAuth, saveRefresToken, saveToken } from "../../functions/Auth";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    deviceId: "12121414",
  });

  if (isAuth()) return <Navigate to="/" replace />;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleApiRequest("/api/login", formData, (data) => {
      localStorage.setItem("Email",formData.username)
      saveToken(data.accessToken, data.expiryTime);
      saveRefresToken(data.refreshToken, data.expiryTime);
      navigate("/");
    });
  };

  return (
    <div className="login_container_fe94 ">
      <div className="container zoomclass">
        <input type="checkbox" id="flip" />
        <div className="cover">
          <div className="front">
            <img
              src="images/logo.png"
              style={{ background: "#212529" }}
              alt=""
            />
            {/* <div className="text">
              <span className="text-1">Every new friend is a <br/> new adventure</span>
              <span className="text-2">Let's get connected</span>
            </div> */}
          </div>
          <div className="back">
            {/*<img className="backImg" src="images/backImg.jpg" alt="">*/}
            <div className="text">
              <span className="text-1">
                Complete miles of journey <br /> with one step
              </span>
              <span className="text-2">Let's get started</span>
            </div>
          </div>
        </div>
        <div className="forms">
          <div className="form-content">
            <div className="login-form">
              <div className="title">Login</div>
              <form onSubmit={handleSubmit}>
                <div className="input-boxes">
                  <div className="input-box">
                    <i className="fas fa-envelope"></i>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Enter your email"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-box">
                    <i className="fas fa-lock"></i>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className="text"><a href="#">Forgot password?</a></div> */}
                  <div className="button input-box">
                    <input type="submit" value="Submit" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
