import axios from 'axios';
import { getRefresh, getToken, removeToken } from './Auth';
const Axios = axios.create();

Axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      return config;
    }
  },
  (Err) => {
    return Promise.reject(Err);
  },
);
const refreshToken = async () => {
  try {
    const refresh = getRefresh(); // Assuming getRefresh() retrieves the refresh token from somewhere
    const data = { refreshToken: refresh };
    const response = await axios.post("/refresh-token", data);
    const accessToken = response.data.data.accessToken;
    window.localStorage.setItem('token', accessToken);
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    window.localStorage.setItem('lastRefreshTime', new Date().getTime()); // Save the current time
  } catch (error) {
    // Handle refresh token failure
    removeToken();
    console.error("Error refreshing token:", error);
  }
};

Axios.interceptors.response.use(
  (res) => {
    return res;
  },
 async (err) => {
    debugger
    const originalRequest = err.config;
    const lastRefreshTime = window.localStorage.getItem('lastRefreshTime');
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastRefreshTime;
    const refreshInterval = 20 * 60 * 60 * 1000; // 20 hours in milliseconds
    
    if (timeDifference > refreshInterval) {
      await refreshToken();
      originalRequest._retry = true;
      return axios(originalRequest);
    }
    return Promise.reject(err);
  },
);

export const handelError = (Err) => {
  let errMsg = 'Something Went Wrong';
  if (Err && Err.message) errMsg = Err.message;
  return {
    error: true,
    message: errMsg,
  };
};

export const header = {
  headers: {
    'content-type': `application/json`,
  },
};

export default Axios;
