import { Link, useNavigate } from "react-router-dom";
import { removeToken } from "../../functions/Auth";
import { useState } from "react";

const Header = ({ isdashboard = false, title = "Dashboard" }) => {
  const navigate = useNavigate();
  const [opendropdown, setopendropdown] = useState(false);
  const logoutHandler = () => {
    // Add logout logic here
    removeToken(); // Assuming this function removes the token
    navigate("/login"); // Redirect to login page after logout
  };
  const email = localStorage.getItem("Email");
  return (
    <header className="zoomclass flex justify-between items-center py-4 px-6 bg-gray-800 text-white">
      <div onClick={() => navigate("/")} className="cursor-pointer">
        <img
          src="images/header.png"
          className="headerlogo"
          style={{ background: "#212529" }}
          alt=""
        />
        {/* <h1>
          {isdashboard ? title : "Short Links"}
        </h1> */}
      </div>

      <div className="px-5 relative">
        <div
          className="roundimage"
          src="/images/logo.png"
          alt="Bordered avatar"
          onClick={() => setopendropdown(!opendropdown)}
        >
          {email?.slice(0, 1)?.toUpperCase()}
        </div>

        {/* <img
          id="avatarButton"
          type="button"
          data-dropdown-toggle="userDropdown"
          data-dropdown-placement="bottom-start"
          className="roundimage"
          src="/images/logo.png"
          alt="User dropdown"
        /> */}

        <div
          id="userDropdown"
          className="Taildropdown"
          style={{ display: `${opendropdown ? "block" : "none"}` }}
        >
          <div className="first">
            {/* <div>Bonnie Green</div> */}
            <div className="font-medium truncate">{email}</div>
          </div>

          <div className="py-1">
            <div
              href="#"
              onClick={logoutHandler}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >
              Sign out
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
// user?.first_name?.slice(0, 1)
