/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Auth';
import DefaultLayout from './components/';
import { Loader } from './components/LoaderComponent';


const App = () => {


  return (
    <Router>
      <Suspense fallback={ <Loader />}>
        <Routes>
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route
            path="/*"
            element={
              <DefaultLayout />
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
